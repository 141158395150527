<template>
  <div>
    <v-checkbox 
      v-model="manifest.restartPolicy.enabled"
      label="Apply Restart Policy"
    ></v-checkbox>
    <div v-if="manifest.restartPolicy.enabled">
      <v-row no-gutters>
        <v-col cols="8">
          <v-select
            class="ma-1"
            v-model="manifest.restartPolicy.name"
            :items="restartPolicyNames"
            label="Restart Policy"
            hide-details
            outlined
          >
          </v-select>
        </v-col>
        <v-col cols="4" v-if="manifest.restartPolicy.name != 'always'">
          <v-text-field
            class="ma-1"
            v-model.number="manifest.restartPolicy.retries"
            label="Maximum Retry Count"
            type="number"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <div class="my-5">
      <h4 class="mb-3">Mapped Ports</h4>
      <v-row v-for="(port, index) in manifest.ports" v-bind:key="index" no-gutters align="center">
        <v-col cols="5">
          <v-text-field
            class="ma-1"
            v-model.number="port.host_port"
            label="Host Port"
            type="number"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-text-field
            class="ma-1"
            v-model.number="port.container_port"
            label="Container Port"
            type="number"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="2" align="center">
          <v-btn
            small
            text
            @click="manifest.ports.splice(index, 1)"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div class="text-right mt-3">
        <v-btn
          x-small
          outlined
          @click="manifest.ports.push({'host_port': null, 'container_port': null})"
        >
          Add Port Mapping
        </v-btn>
      </div>
    </div>

    <div class="my-5">
      <h4 class="mb-3">Environment Variables</h4>
      <v-row v-for="(variable, index) in manifest.environment" v-bind:key="index" no-gutters align="center">
        <v-col cols="4">
          <v-text-field
            class="ma-1"
            v-model="variable.key"
            label="Key"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            class="ma-1"
            v-model="variable.value"
            label="Value"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="2" align="center">
          <v-btn
            small
            text
            @click="manifest.environment.splice(index, 1)"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div class="text-right mt-3">
        <v-btn
          x-small
          outlined
          @click="manifest.environment.push({'key': null, 'value': null})"
        >
          Add Variable
        </v-btn>
      </div>
    </div>

    <div class="my-5">
      <h4 class="mb-3">Mapped Volumes</h4>
      <v-row v-for="(volume, index) in manifest.volumes" v-bind:key="index" no-gutters align="center">
        <v-col cols="4">
          <v-text-field
            class="ma-1"
            v-model="volume.host_path"
            label="Host Path"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            class="ma-1"
            v-model="volume.container_path"
            label="Container Path"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="volume.mode"
            :items="volumeModes"
            label="Access Mode"
            hide-details
            outlined
          >
          </v-select>
        </v-col>
        <v-col cols="2" align="center">
          <v-btn
            small
            text
            @click="manifest.volumes.splice(index, 1)"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div class="text-right mt-3">
        <v-btn
          x-small
          outlined
          @click="manifest.volumes.push({'host_path': null, 'container_path': null, 'mode': 'ro'})"
        >
          Add Volume
        </v-btn>
      </div>
    </div>

    <div class="my-5">
      <h4 class="mb-3">Mapped Devices</h4>
      <v-row v-for="(device, index) in manifest.devices" v-bind:key="index" no-gutters align="center">
        <v-col cols="4">
          <v-text-field
            class="ma-1"
            v-model="device.host_path"
            label="Host Path"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            class="ma-1"
            v-model="device.container_path"
            label="Container Path"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="device.mode"
            :items="deviceModes"
            label="Access Mode"
            hide-details
            outlined
          >
          </v-select>
        </v-col>
        <v-col cols="2" align="center">
          <v-btn
            small
            text
            @click="manifest.devices.splice(index, 1)"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div class="text-right mt-3">
        <v-btn
          x-small
          outlined
          @click="manifest.devices.push({'host_path': null, 'container_path': null, 'mode': 'rwm'})"
        >
          Add Device
        </v-btn>
      </div>
    </div>

    <div class="my-5">
      <h4 class="mb-3">Advanced</h4>
      <v-alert dense type="error">
        The following settings are intended for advanced use cases only, they can expose the device system to intruders if not used properly.
      </v-alert>
       <v-row no-gutters align="center">
        <v-col cols="6">
          <v-select
            class="ma-1"
            v-model="manifest.networkMode"
            :items="networkModes"
            label="Network Mode"
            hide-details
            outlined
          >
          </v-select>
        </v-col>
        <v-col cols="6" v-if="isAdmin">
          <div class='d-flex justify-center'>
            <v-checkbox 
              v-model="manifest.privileged"
              label="Privileged Mode"
            ></v-checkbox>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'EditManifest',

    props: [
      'value',
      'isAdmin'
    ],

    data() {
      return {
        restartPolicyNames: [
          'on-failure',
          'always'
        ],
        
        volumeModes: [
          'rw',
          'ro'
        ],
        
        deviceModes: [
          'r',
          'rw',
          'rwm'
        ],
        
        networkModes: [
          'bridge',
          'host',
          'container',
          'none',
        ],

        manifest: {
          restartPolicy: {
            enabled: false,
            name: 'always',
            retries: 5
          },
          environment: [],
          volumes: [],
          ports: [],
          devices: [],
          privileged: false,
          networkMode: 'bridge'
        }
      }
    },

    mounted() {
      if (this.value) {
        this.loadManifest(this.value)
      }
    },

    watch: {
      manifest: {
        deep: true,

        handler() {
          var options = {}

          if (this.manifest.restartPolicy.enabled) {
            if (this.manifest.restartPolicy.name == 'always') {
              this.manifest.restartPolicy.retries = 0
            }

            options.restart_policy = {
              name: this.manifest.restartPolicy.name,
              MaximumRetryCount: this.manifest.restartPolicy.retries
            }
          }

          if (this.manifest.ports.length > 0) {
            options.ports = this.manifest.ports
          }

          if (this.manifest.environment.length > 0) {
            options.environment = this.manifest.environment
          }

          if (this.manifest.volumes.length > 0) {
            options.volumes = this.manifest.volumes
          }

          if (this.manifest.devices.length > 0) {
            options.devices = this.manifest.devices
          }

          if (this.manifest.networkMode) {
            options.network_mode = this.manifest.networkMode
          }

          if (this.manifest.privileged) {
            options.privileged = this.manifest.privileged
          }

          this.$emit('input', options)
        }
      },

      value() {
        if (this.value) {
          this.loadManifest(this.value)
        }
      }
    },

    methods: {
      loadManifest(manifest) {
        this.manifest.restartPolicy.enabled = !!manifest.restart_policy?.name
        this.manifest.restartPolicy.name = manifest.restart_policy?.name ?? this.manifest.restartPolicy.name
        this.manifest.restartPolicy.retries = manifest.restart_policy?.MaximumRetryCount ?? this.manifest.restartPolicy.retries

        this.manifest.environment = manifest.environment ?? this.manifest.environment
        this.manifest.volumes = manifest.volumes ?? this.manifest.volumes
        this.manifest.ports = manifest.ports ?? this.manifest.ports
        this.manifest.devices = manifest.devices ?? this.manifest.devices
        this.manifest.privileged = !!manifest.privileged
        this.manifest.networkMode = manifest.network_mode ?? this.manifest.networkMode
      }
    }
  }
</script>
