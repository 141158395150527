import Vue from 'vue'
import '../scss/index.scss'

// Global components setup

// Videon Common
import VideonLogo from '../components/common/VideonLogo.vue'
Vue.component('videon-logo', VideonLogo)

import VideonCard from '../components/common/VideonCard.vue'
Vue.component('videon-card', VideonCard)

import VideonMetricTile from '../components/common/VideonMetricTile.vue'
Vue.component('videon-metric-tile', VideonMetricTile)

import VideonStateTile from '../components/common/VideonStateTile.vue'
Vue.component('videon-state-tile', VideonStateTile)

import DataTablePagination from '../components/common/DataTablePagination.vue'
Vue.component('data-table-pagination', DataTablePagination)

// Metrics related
import LineChart from '../components/common/metrics/LineChart.vue'
Vue.component('line-chart', LineChart)

import VideonChart from '../components/common/metrics/VideonChart.vue'
Vue.component('videon-chart', VideonChart)

import VideonChartTile from '../components/common/metrics/VideonChartTile.vue'
Vue.component('videon-chart-tile', VideonChartTile)

import TimezoneSelector from '../components/common/TimezoneSelector.vue'
Vue.component('timezone-selector', TimezoneSelector)

// Nav related
import NavItem from '../components/common/nav/NavItem.vue'
Vue.component('nav-item', NavItem)

import NavItemGroup from '../components/common/nav/NavItemGroup.vue'
Vue.component('nav-item-group', NavItemGroup)


// Labels
import UserLabel from '../components/common/labels/UserLabel.vue'
Vue.component('user-label', UserLabel)

import AccessLevelLabel from '../components/common/labels/AccessLevelLabel.vue'
Vue.component('access-level-label', AccessLevelLabel)

import DeviceLabel from '../components/common/labels/DeviceLabel.vue'
Vue.component('device-label', DeviceLabel)

import FleetStatusLabel from '../components/common/labels/FleetStatusLabel.vue'
Vue.component('fleet-status-label', FleetStatusLabel)

import StreamingStatusLabel from '../components/common/labels/StreamingStatusLabel.vue'
Vue.component('streaming-status-label', StreamingStatusLabel)

import ProductLabel from '../components/common/labels/ProductLabel.vue'
Vue.component('product-label', ProductLabel)

import ProcessorsStatusLabel from '../components/common/labels/ProcessorsStatusLabel.vue'
Vue.component('processors-status-label', ProcessorsStatusLabel)

import FirmwareVersionLabel from '../components/common/labels/FirmwareVersionLabel.vue'
Vue.component('firmware-version-label', FirmwareVersionLabel)

import AlertStateLabel from '../components/common/labels/AlertStateLabel.vue'
Vue.component('alert-state-label', AlertStateLabel)

import InputStatusLabel from '../components/common/labels/InputStatusLabel.vue'
Vue.component('input-status-label', InputStatusLabel)

import OutputStatusLabel from '../components/common/labels/OutputStatusLabel.vue'
Vue.component('output-status-label', OutputStatusLabel)

import DeviceStatusTiles from '../components/common/labels/DeviceStatusTiles.vue'
Vue.component('device-status-tiles', DeviceStatusTiles)

import UserAvatar from '../components/common/UserAvatar.vue'
Vue.component('user-avatar', UserAvatar)

import ContainerInfo from '../components/common/labels/ContainerInfo.vue'
Vue.component('container-info', ContainerInfo)

import LicenseLabel from '../components/common/labels/LicenseLabel.vue'
Vue.component('license-label', LicenseLabel)

// controls
import OutputControl from '../components/common/control/OutputControl.vue'
Vue.component('output-control', OutputControl)

import RebootDevice from '../components/common/control/RebootDevice.vue'
Vue.component('reboot-device', RebootDevice)

import ResetSettings from '../components/common/control/ResetSettings.vue'
Vue.component('reset-settings', ResetSettings)

import ManageDeviceButton from '../components/common/control/ManageDeviceButton.vue'
Vue.component('manage-device-button', ManageDeviceButton)

import UpdateContainer from '../components/common/control/UpdateContainer.vue'
Vue.component('update-container', UpdateContainer)

import DownloadLogs from '../components/common/control/DownloadLogs.vue'
Vue.component('download-logs', DownloadLogs)

import ADBControl from '../components/common/control/ADBControl.vue'
Vue.component('adb-control', ADBControl)

import LocalUIPassword from '../components/common/control/LocalUIPassword.vue'
Vue.component('local-ui-password', LocalUIPassword)

import StorageDevices from '../components/common/control/StorageDevices.vue'
Vue.component('storage-devices', StorageDevices)

import EntitlementDebug from '../components/common/control/EntitlementDebug.vue'
Vue.component('entitlement-debug', EntitlementDebug)

// 3rd party
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue"
Vue.component('vue-tel-input-vuetify', VueTelInputVuetify)



export default {}
